import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import {
  Box,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import { navigate } from 'gatsby'
import {
  Association,
  useGetAssociationsQuery,
} from 'manage-tritag/services/api/endpoints/associations'
import { noLimitParams } from 'manage-tritag/utils/string'
import Spinner from 'manage-tritag/components/loading/spinner'
import { useGetCompetitionsQuery } from 'manage-tritag/services/api/endpoints/competitions'
import {
  Match,
  useLazyGetMatchesByCompetitionQuery,
} from 'manage-tritag/services/api/endpoints/matches'
import {
  Team,
  useLazyGetTeamPlayersQuery,
} from 'manage-tritag/services/api/endpoints/teams'
import { useAddIncidentMutation } from 'manage-tritag/services/api/endpoints/incidents'
import {
  SecondGrid,
  StyledButton,
  StyledButtonDiv,
  StyledGridContainer,
  StyledTextField,
  TripleFormContent,
} from './styles'

const IncidentCreateForm = () => {
  const { data: associations, isLoading: assocLoading } =
    useGetAssociationsQuery(noLimitParams)
  const [createIncident, { isLoading: createLoading }] =
    useAddIncidentMutation()
  const { data: competitions, isLoading: competitionLoading } =
    useGetCompetitionsQuery({ ...noLimitParams, currentSeason: true })
  const [matchesTrigger, matchesResult] = useLazyGetMatchesByCompetitionQuery()
  const [playersTrigger, playersResult] = useLazyGetTeamPlayersQuery()
  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues: {
      association: '',
      competition: '',
      injuryType: '',
      injuryArea: '',
      match: '',
      team: '',
      player: '',
      incidentType: '',
      allegedIncident: '',
      directedTowards: '',
      actionTaken: '',
      incidentComment: '',
      assessment: '',
      result: '',
      suspensionEnds: '',
      outcomeComment: '',
    },
  })
  const watchAllFields = watch()
  const [teams, setTeams] = useState<Team[] | undefined>(undefined)
  const incidentOptions: string[] = [
    'Offensive language or gestures',
    'Deliberately pushing, tripping, grabbing or holding tags',
    'Deliberate contact using elbow, shoulder etc.',
    'Striking (open hand)',
    'Striking (closed fist)',
    'Participating in fight',
    'Other (add in comments)',
  ]

  const directedTowardsOptions: string[] = [
    'participant',
    'official',
    'spectator',
  ]

  const onFieldActionIncidentOptions: string[] = [
    'None',
    'Warning',
    'Sin bin',
    'Send off',
  ]
  const onFieldActionInjuryOptions: string[] = [
    'None',
    'First aid applied',
    'Ambulance called',
  ]
  const injuryTypeOptions: string[] = [
    'Soft Tissue injuries (muscle tears, sprained ligaments etc.)',
    'Impact injuries (broken bones, corks, cuts etc.)',
  ]
  const injuryAreaOptions: string[] = [
    'Upper body (waist up)',
    'Lower Limb (waist down including buttock injuries)',
  ]
  const assessmentOfReportIncidentOptions: string[] = [
    'Noted',
    'Warning',
    'Disciplinary action required',
  ]
  const resultIncidentOptions: string[] = [
    'No further action required',
    'On-field action sufficient',
    'Suspension',
  ]
  const resultInjuryOptions: string[] = [
    'No further action required',
    'Medical clearance required',
  ]

  useEffect(() => {
    if (watchAllFields.competition && competitions) {
      const selectedCompetition = competitions.find(
        x => x._id === watchAllFields.competition,
      )

      if (selectedCompetition?.teams.length) {
        setTeams(selectedCompetition?.teams)
      } else {
        setTeams(undefined)
      }

      matchesTrigger(watchAllFields.competition)
    }
  }, [competitions, matchesTrigger, setTeams, watchAllFields.competition])

  useEffect(() => {
    if (watchAllFields.team && teams) {
      playersTrigger(watchAllFields.team)
    }
  }, [playersTrigger, teams, watchAllFields.team])

  const onSubmit: SubmitHandler<Association> = async (createData: any) => {
    const reqData = {
      association: createData.association,
      competition: createData.competition,
      match: createData.match,
      type: createData.incidentType,
      team: createData.team,
      participant: createData.player,
      incident: {
        allegedIncident: createData.allegedIncident,
        directedTowards: createData.directedTowards,
        actionTaken: createData.actionTaken,
      },
      injury: {
        injuryType: createData.injuryType,
        injuryArea: createData.injuryArea,
        actionTaken: createData.actionTaken,
      },
      comment: createData.incidentComment,
      file: '',
      outcome: {
        assessment: createData.assessment,
        result: createData.result,
        suspensionEnds: createData.suspensionEnds,
        comments: createData.outcomeComment,
        actionedBy: '',
      },
    }
    createIncident(reqData)
    reset()
    toast.success('Incident added succesfully.')
    navigate('/incidents')
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <StyledGridContainer container justifyContent="space-between">
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            sx={{ fontWeight: 'bold', color: '#008174', marginBottom: '2rem' }}
          >
            Incident details
          </Typography>
        </Grid>
        <SecondGrid item xs={12} sm={12} md={12}>
          <TripleFormContent>
            <FormControl variant="filled" size="small" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Association *
              </InputLabel>
              <Controller
                name="association"
                control={control}
                render={({ field }) => (
                  <Select
                    inputProps={{ tabIndex: 1 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    required
                    {...field}
                  >
                    {!assocLoading &&
                      associations &&
                      associations.map(association => (
                        <MenuItem key={association._id} value={association._id}>
                          {association.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>

            <FormControl variant="filled" size="small" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Competition *
              </InputLabel>
              <Controller
                name="competition"
                control={control}
                render={({ field }) => (
                  <Select
                    inputProps={{ tabIndex: 1 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    {...field}
                    required
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {!competitionLoading &&
                      competitions &&
                      competitions.map(competition => (
                        <MenuItem key={competition._id} value={competition._id}>
                          {competition.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>

            <FormControl variant="filled" size="small" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Matches *
              </InputLabel>
              <Controller
                name="match"
                control={control}
                render={({ field }) => (
                  <Select
                    inputProps={{ tabIndex: 7 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    {...field}
                    required
                  >
                    {matchesResult.data &&
                      matchesResult.data.length &&
                      matchesResult.data.map((match: Match) => (
                        <MenuItem key={match._id} value={match._id}>
                          Round {match.round && match.round.number}{' '}
                          {match.homeTeam && match.homeTeam.name} vs{' '}
                          {match.awayTeam && match.awayTeam.name}
                          {/* {match.matchDisplayName
                            ? match.matchDisplayName
                            : match.round.number} */}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </TripleFormContent>
        </SecondGrid>

        <SecondGrid item xs={12} sm={12} md={12}>
          <TripleFormContent>
            <FormControl variant="filled" size="small" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Incident Type *
              </InputLabel>
              <Controller
                name="incidentType"
                control={control}
                render={({ field }) => (
                  <Select
                    inputProps={{ tabIndex: 7 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    {...field}
                    required
                  >
                    <MenuItem value="Incident">Incident</MenuItem>
                    <MenuItem value="Injury">Injury</MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            <FormControl variant="filled" size="small" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Team *
              </InputLabel>
              <Controller
                name="team"
                control={control}
                render={({ field }) => (
                  <Select
                    inputProps={{ tabIndex: 8 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    {...field}
                  >
                    {teams &&
                      teams.length &&
                      teams.map((team: Team) => (
                        <MenuItem key={team._id} value={team._id}>
                          {team.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>

            <FormControl variant="filled" size="small" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Player *
              </InputLabel>
              <Controller
                name="player"
                control={control}
                render={({ field }) => (
                  <Select
                    inputProps={{ tabIndex: 8 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    {...field}
                  >
                    {playersResult &&
                      playersResult.data &&
                      playersResult.data.length &&
                      playersResult.data.map((player: any) => (
                        <MenuItem key={player._id} value={player._id}>
                          {player.firstName} {player.lastName}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </TripleFormContent>
        </SecondGrid>

        <SecondGrid item xs={12} sm={12} md={12}>
          <TripleFormContent>
            {watchAllFields.incidentType === 'Incident' ? (
              <>
                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Alleged incident *
                  </InputLabel>
                  <Controller
                    name="allegedIncident"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputProps={{ tabIndex: 7 }}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        {...field}
                        required
                      >
                        {watchAllFields.incidentType === 'Incident' ? (
                          incidentOptions.map(incident => (
                            <MenuItem key={incident} value={incident}>
                              {incident}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="N/A">N/A</MenuItem>
                        )}
                      </Select>
                    )}
                  />
                </FormControl>

                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Directed towards *
                  </InputLabel>
                  <Controller
                    name="directedTowards"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputProps={{ tabIndex: 7 }}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        {...field}
                        required
                      >
                        {watchAllFields.incidentType === 'Incident' ? (
                          directedTowardsOptions.map(value => (
                            <MenuItem key={value} value={value}>
                              {value}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="N/A">N/A</MenuItem>
                        )}
                      </Select>
                    )}
                  />
                </FormControl>
              </>
            ) : (
              <>
                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Injury Type *
                  </InputLabel>
                  <Controller
                    name="injuryType"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputProps={{ tabIndex: 7 }}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        {...field}
                        required
                      >
                        {injuryTypeOptions.map(type => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>

                <FormControl
                  variant="filled"
                  size="small"
                  sx={{ width: '100%' }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    Injury Area *
                  </InputLabel>
                  <Controller
                    name="injuryArea"
                    control={control}
                    render={({ field }) => (
                      <Select
                        inputProps={{ tabIndex: 7 }}
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        disableUnderline
                        {...field}
                        required
                      >
                        {injuryAreaOptions.map(type => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </>
            )}

            <FormControl variant="filled" size="small" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                On-field action taken *
              </InputLabel>
              <Controller
                name="actionTaken"
                control={control}
                render={({ field }) => (
                  <Select
                    inputProps={{ tabIndex: 7 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    {...field}
                    required
                  >
                    {watchAllFields.incidentType === 'Incident'
                      ? onFieldActionIncidentOptions.map(value => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))
                      : onFieldActionInjuryOptions.map(value => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                  </Select>
                )}
              />
            </FormControl>
          </TripleFormContent>
        </SecondGrid>

        <SecondGrid item xs={12} sm={12} md={12}>
          <Controller
            name="incidentComment"
            control={control}
            render={({ field }) => (
              <StyledTextField
                label="Comments"
                type="text"
                size="small"
                variant="filled"
                multiline
                rows={4}
                InputProps={{ disableUnderline: true }}
                inputProps={{ tabIndex: 10 }}
                {...field}
              />
            )}
          />
        </SecondGrid>

        <Box mt={2} mb={2}>
          <label>Upload supporting file</label>
          <br />
          <input
            type="file"
            id="avatar"
            name="avatar"
            accept="image/png, image/jpeg"
          />
        </Box>

        <Grid item xs={12} sm={12} md={12}>
          <Typography
            sx={{ fontWeight: 'bold', color: '#008174', marginBottom: '2rem' }}
          >
            Outcome
          </Typography>
        </Grid>

        <SecondGrid item xs={12} sm={12} md={12}>
          <TripleFormContent>
            <FormControl variant="filled" size="small" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Assessment of report
              </InputLabel>
              <Controller
                name="assessment"
                control={control}
                render={({ field }) => (
                  <Select
                    inputProps={{ tabIndex: 7 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    {...field}
                  >
                    {watchAllFields.incidentType === 'Incident' ? (
                      assessmentOfReportIncidentOptions.map(incident => (
                        <MenuItem key={incident} value={incident}>
                          {incident}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="Noted">Noted</MenuItem>
                    )}
                  </Select>
                )}
              />
            </FormControl>

            <FormControl variant="filled" size="small" sx={{ width: '100%' }}>
              <InputLabel id="demo-simple-select-filled-label">
                Result
              </InputLabel>
              <Controller
                name="result"
                control={control}
                render={({ field }) => (
                  <Select
                    inputProps={{ tabIndex: 7 }}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    disableUnderline
                    {...field}
                  >
                    {watchAllFields.incidentType === 'Incident'
                      ? resultIncidentOptions.map(value => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))
                      : resultInjuryOptions.map(value => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                  </Select>
                )}
              />
            </FormControl>

            <Controller
              name="suspensionEnds"
              control={control}
              render={({ field }) => (
                <StyledTextField
                  label="Suspension Ends"
                  type="date"
                  size="small"
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ tabIndex: 10 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
            />
          </TripleFormContent>
        </SecondGrid>

        <SecondGrid item xs={12} sm={12} md={12}>
          <Controller
            name="outcomeComment"
            control={control}
            render={({ field }) => (
              <StyledTextField
                label="Comments"
                type="text"
                size="small"
                variant="filled"
                multiline
                rows={4}
                InputProps={{ disableUnderline: true }}
                inputProps={{ tabIndex: 10 }}
                {...field}
              />
            )}
          />
        </SecondGrid>
      </StyledGridContainer>

      <StyledButtonDiv>
        <div />

        <div>
          <StyledButton
            variant="outlined"
            onClick={() => {
              navigate('/incidents')
            }}
            style={{
              border: '2px solid #008174',
              color: '#000',
              marginRight: '1.5rem',
            }}
          >
            CANCEL
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            type="submit"
            style={{ width: '83px' }}
          >
            {createLoading ? <Spinner size={22} /> : 'SAVE'}
          </StyledButton>
        </div>
      </StyledButtonDiv>
    </Box>
  )
}

export default IncidentCreateForm
